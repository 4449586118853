// Create an hidden form to build content block server-side and append it with a stream
import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = { type: String };

  submitForm(e) {
    e.preventDefault();
    const form = document.getElementById("build-form");

    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "block_type");
    input.setAttribute("value", this.typeValue);
    form.appendChild(input);
    // Count elements to set position server-side
    input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "count");
    input.setAttribute("value", document.getElementById("content-blocks-list").childElementCount);
    form.appendChild(input);
    // Submit form
    Turbo.navigator.submitForm(form);
  }
}
