import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="pos--submit"
export default class extends Controller {
  static targets = ["confirmBtn", "declineBtn"];
  static outlets = ["barscanner"];

  initialize() {
    this.keyDownHandler = (e) => this.onKeyDown(e);
    window.addEventListener("keydown", this.keyDownHandler);
  }

  disconnect() {
    window.removeEventListener("keydown", this.keyDownHandler);
  }

  submit(e) {
    e.preventDefault();
    this.element.disabled = true;
    Turbo.navigator.submitForm(document.getElementById("js-pos-form"));
  }

  onKeyDown(e) {
    if (e.keyCode == 13 && e.ctrlKey) {
      e.preventDefault();
      // When press "enter" submit POS order
      Turbo.navigator.submitForm(document.getElementById("js-pos-form"));
    }
    if (e.keyCode == 27 && this.hasDeclineBtnTarget) {
      e.preventDefault();
      // When press "ESC" close the POS confirm modal
      this.declineBtnTarget.click();
    }
  }

  enableBarscanner() {
    window.removeEventListener("keydown", this.keyDownHandler);
    this.barscannerOutlet.skipValue = false;
  }
}
