import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products--color"
export default class extends Controller {
  static targets = ["colorInput"]

  onColorChange(e) {
    this.colorInputTarget.value = e.target.options[e.target.selectedIndex].text
  }
}
