import { Controller } from "@hotwired/stimulus";
import { printZPL, printPDF, printEPL } from "../lib/qz-helper";
import $ from "jquery";

// Connects to data-controller="print"
export default class extends Controller {
  static targets = ["typology", "button"];
  static values = { url: String, printerName: String, labelsCount: String, samplesCount: String };

  printZPL(e) {
    e.preventDefault();
    $.get(this.urlValue).done((data) => {
      printZPL(this.printerNameValue, data);
    });
  }

  printPDF(e) {
    e.preventDefault();
    $.get(this.urlValue).done((data) => {
      printPDF(this.printerNameValue, data);
    });
  }

  updateProductLabelsButton() {
    if (this.typologyTarget.value == "sample") {
      this.buttonTarget.innerHTML = `Stampa ${this.samplesCountValue} etichette`;
    } else {
      this.buttonTarget.innerHTML = `Stampa ${this.labelsCountValue} etichette`;
    }
  }

  printProductLabels(e) {
    e.preventDefault();

    $.post(this.urlValue, { typology: this.typologyTarget.value }).done((data) => {
      printEPL(this.printerNameValue, data);
    });
  }
}
