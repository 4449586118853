import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "destroyInput"];
  static values = { persisted: Boolean };

  deleteImage(e) {
    e.preventDefault();
    if (this.persistedValue) {
      // Image is saved on db, set an hidden field to remove the image
      this.containerTarget.classList.add("hide");
      this.destroyInputTarget.value = "1";
    } else {
      // Temp image, just remove the HTML element
      this.containerTarget.remove();
    }
  }
}
