import { Controller } from "@hotwired/stimulus";
import Splide from "@splidejs/splide";
import "@splidejs/splide/css/core";

// Connects to data-controller="slider"
export default class extends Controller {
  connect() {
    this.slider = new Splide(this.element, { arrows: false, pagination: false, heightRatio: 0.9 });
    this.slider.mount();
  }

  disconnect() {
    this.slider.destroy();
  }
}
