import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autosubmit"
export default class extends Controller {
  static targets = ["selects", "submit"];

  searchOnType() {
    clearTimeout(this.submitTimer);

    this.submitTimer = setTimeout(() => {
      this.submitTarget.click();
    }, 800);
  }

  searchOnSelectChange() {
    clearTimeout(this.submitTimer);
    this.submitTimer = setTimeout(() => {
      this.submitTarget.click();
    }, 500);
  }
}
