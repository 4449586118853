import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default class extends Controller {
  static values = {
    content: String,
    placement: { type: String, default: "top" },
    trigger: { type: String, default: "mouseenter focus" },
  };

  connect() {
    this.tooltip = tippy(this.element, {
      content: this.contentValue,
      trigger: this.triggerValue,
      allowHTML: true,
      placement: this.placementValue,
    });
  }

  disconnect() {
    this.tooltip.destroy();
    this.tooltip = undefined;
  }
}
