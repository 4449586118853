import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sdiSelect", "pecRow", "sdiRow"];

  connect() {
    this.calculate();
  }

  calculate() {
    const selectValue = this.sdiSelectTarget.value;

    if (selectValue == "pec") {
      this.pecRowTarget.classList.remove("hide");
      this.sdiRowTarget.classList.add("hide");
    } else if (selectValue == "sdi") {
      this.pecRowTarget.classList.add("hide");
      this.sdiRowTarget.classList.remove("hide");
    } else {
      this.pecRowTarget.classList.add("hide");
      this.sdiRowTarget.classList.add("hide");
    }
  }
}
