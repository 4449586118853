import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="suppliers-orders--liting-filters"
export default class extends Controller {
  static targets = ["storage"];

  toggleStorageFilter(e) {
    if (e.target.checked) {
      this.element.classList.add("show-storage")
    } else {
      this.element.classList.remove("show-storage")
    }
  }

  toggleStatsFilter(e) {
    if (e.target.checked) {
      this.element.classList.add("show-stats")
    } else {
      this.element.classList.remove("show-stats")
    }
  }
}
