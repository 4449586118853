import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import $ from "jquery";

export default class extends Controller {
  static targets = ["productId", "productSku"];

  showSearch(e) {
    e.preventDefault();
    $("#pos-product-search-page").fadeIn("fast", () => {
      $("#js-search-product").val("").focus();
    });
  }

  closeSearch(e) {
    e.preventDefault();
    $("#pos-product-search-page").fadeOut("fast", () => {
      $("#js-search-product").val("");
      $("#produt-search-results").html("").removeClass("grid-container-results");
    });
  }

  checkIfProductExistById(e) {
    // This function need to check if a product is already prensent on POS.
    // If exists increase the quantity instead of making a request to build a new line
    if (this.productIdTarget.value) {
      $(".product-line").each((i, obj) => {
        const product = $(obj).find(".js-pos-line-product-id").val();
        // Product already exists on POS
        if (product == this.productIdTarget.value) {
          // Stop form submit
          e.preventDefault();
          // Remove the produtc_id from form bescause is not submitted
          this.productIdTarget.value = "";
          // Close the search box
          this.closeSearch(e);
          // Increase line quantity and animate it
          this.updateLineQuantity($(obj));
        }
      });
    }
  }

  checkIfProductExistBySku(e) {
    // Function used by barscanner search
    // This function need to check if a product is already prensent on POS.
    // If exists increase the quantity instead of making a request to build a new line
    if (this.productSkuTarget.value) {
      $(".product-line").each((i, obj) => {
        const product = $(obj).find(".js-pos-line-product-sku").val();
        // Product already exists on POS
        if (product == this.productSkuTarget.value) {
          // Stop form submit
          e.preventDefault();
          // Remove the produtc_sku from form bescause is not submitted
          this.productSkuTarget.value = "";
          // Increase line quantity and animate it
          this.updateLineQuantity($(obj));
        }
      });
    }
  }

  updateLineQuantity(element) {
    // element is the HTML line passed by jQuery
    // Increase and set new quantity
    const quantity = (parseInt(element.find(".js-pos-line-quantity").val()) || 0) + 1;
    element.find(".js-pos-line-quantity").val(quantity);
    // Scroll to the updated line and animate background
    $(".pos-items-container").animate(
      {
        scrollTop:
          $(".pos-items-container").scrollTop() - $(".pos-items-container").offset().top + element.offset().top,
      },
      500
    );
    element.addClass("highlight");
    setTimeout(() => {
      element.removeClass("highlight");
    }, 1600);
    // Create a custom event that calls a function present on calculation controller
    // An action on the pos line will listen to this custom event and fire the function on calculation controller
    const event = new CustomEvent("forceCalculateItemsTotal", { bubbles: true, cancelable: true });
    document.dispatchEvent(event);
  }

  openSearchOnError(e) {
    if (!e.detail.success) {
      $("#pos-product-search-page").fadeIn("fast", () => {
        $("#js-search-product").val(this.productSkuTarget.value).focus();
      });
    }
  }

  addEmptyLine(e) {
    // Submit form without a product id
    e.preventDefault();
    $("#js-add-product").val("");
    Turbo.navigator.submitForm(document.getElementById("js-add-product-form"));
  }
}
