import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="field-select"
export default class extends Controller {
  static targets = ["content", "copyIcon", "checkIcon"];

  copyContent() {
    const content = this.contentTarget.value;
    navigator.clipboard.writeText(content);
    this.copyIconTarget.classList.add("hide");
    this.checkIconTarget.classList.remove("hide");

    setTimeout(() => {
      this.copyIconTarget.classList.remove("hide");
      this.checkIconTarget.classList.add("hide");
    }, 800);
  }

  selectContent() {
    this.contentTarget.focus();
    this.contentTarget.select();
  }
}
