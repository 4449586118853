import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="input-edit"
export default class extends Controller {
  static targets = ["input", "editButton", "confirmButton"];

  enableInput() {
    this.inputTarget.readOnly = false;
    this.inputTarget.classList.remove("sortable-trigger", "cursor-grab");
    this.editButtonTarget.classList.add("hide");
    this.confirmButtonTarget.classList.remove("hide");
  }

  disableInput() {
    this.inputTarget.readOnly = true;
    this.inputTarget.classList.add("sortable-trigger", "cursor-grab");
    this.editButtonTarget.classList.remove("hide");
    this.confirmButtonTarget.classList.add("hide");
  }
}
