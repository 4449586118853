import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import AWS from "aws-sdk";

// https://stackoverflow.com/questions/34526851/upload-files-to-amazon-s3-with-dropzone-js-issue
// https://github.com/kfei/vue-s3-dropzone/blob/master/frontend/src/components/Dropzone.vue

export default class extends Controller {
  static values = { key: String, bucket: String, region: String, existing: Array };
  static targets = ["imageUrlTag", "imageDeleteTag"];

  initialize() {
    const existingImages = this.existingValue;
    const element = this.element;
    const poolID = this.keyValue;
    const bucket = this.bucketValue;
    const region = this.regionValue;

    // Make sure Dropzone doesn't try to attach itself to the
    // element automatically.
    // This behaviour will change in future versions.
    Dropzone.autoDiscover = false;

    // Instantiate Dropzone
    this.dropzone = new Dropzone(element, {
      thumbnailMethod: "contain",
      // The URL will be changed for each new file being processing
      url: "/",
      maxFiles: 1,

      // Since we're going to do a `PUT` upload to S3 directly
      method: "put",

      previewTemplate: document.querySelector("#dropzone-template-container").innerHTML,
      previewsContainer: `#${element.id} .drop-images-wrapper`,
      clickable: `#${element.id} .open-dropzone`,

      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        let _send = xhr.send;
        xhr.send = () => {
          _send.call(xhr, file);
        };
      },

      // Upload one file at a time since we're using the S3 pre-signed URL scenario
      parallelUploads: 1,
      uploadMultiple: false,

      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      header: "",

      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,

      init() {
        // Add to dropzone file aleready present on server
        const myDropzone = this;
        const cors = "Anonymous"; // need it otherwise S3 blocks the image
        let count = 0;

        existingImages.forEach((element) => {
          myDropzone.displayExistingFile({ name: `Filename ${count}`, size: 12345 }, element, null, cors);
          count++;
        });
        // Update maxFiles because doesn't change authomatically
        myDropzone.options.maxFiles = myDropzone.options.maxFiles - count;
        myDropzone.on("maxfilesexceeded", (file) => {
          // Don't permit the upload if the maxFiles limit is reached
          this.removeFile(file);

          element.classList.add("dz-started");
        });
      },
      // Here we request a signed upload URL when a file being accepted
      accept(file, done) {
        const myDropzone = this;
        AWS.config.region = region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: poolID,
        });

        const s3 = new AWS.S3();
        const randomFolder = Math.floor(Math.random() * 100000000);
        // Remove special chars and white spaces
        const fileName = file.name.replace(/[^A-Za-z0-9\-\_\.]\s/, "-");
        var params = {
          Bucket: bucket,
          Key: `tmp/${randomFolder}/${fileName}`,
          ACL: "public-read",
          ContentType: file.type,
          Expires: 1800, // 30m
        };

        s3.getSignedUrl("putObject", params, function (err, url) {
          if (err) {
            done("Failed to get an S3 signed upload URL", err);
          } else {
            file.uploadURL = url;
            done();
            // Manually process each file
            setTimeout(() => myDropzone.processFile(file));
          }
        });
      },
    });

    // Set signed upload URL for each file
    this.dropzone.on("processing", (file) => {
      this.dropzone.options.url = file.uploadURL;
    });
    this.dropzone.on("addedfile", (file) => {
      // hide upload button
      element.classList.add("dz-started");
    });
    this.dropzone.on("success", (file) => {
      // Remove query string from url
      const fileUrl = file.uploadURL.split("?")[0];
      this.imageUrlTagTarget.value = fileUrl;
      if (this.hasImageDeleteTagTarget) {
        this.imageDeleteTagTarget.value = "";
      }
    });
  }

  deleteAll(e) {
    e.preventDefault();
    this.imageUrlTagTarget.value = "";
    this.imageDeleteTagTarget.value = "1";
    // Remove the image inside the container. Don't use .removeAll() function because doesn't work on existing image
    this.element.getElementsByClassName("drop-images-wrapper")[0].innerHTML = "";
    // Restore maxFiles otherwise a new drop is blocked
    this.dropzone.options.maxFiles = 1;
    // Show buttons
    this.element.classList.remove("dz-started");
  }
}
