import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "variants"];

  connect() {
    this.change();
  }

  change() {
    if (this.selectTarget.value == "none") {
      // Manual creation variant
      this.variantsTargets.forEach((i) => i.classList.add("hide"));
      document.getElementById("blank-variants-container").classList.remove("hide");
    } else if (this.selectTarget.value) {
      this.variantsTargets.forEach((i) => i.classList.add("hide"));
      document.getElementById("blank-variants-container").classList.add("hide");
      document.getElementById(`group_${this.selectTarget.value}`).classList.remove("hide");
    }
  }
}
