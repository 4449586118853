import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="suppliers-orders--line-sum"
export default class extends Controller {
  static targets = ["counterInput", "quantityInput"];

  connect() {
    this.updateCounter();
  }

  updateCounter() {
    let total = 0;
    this.quantityInputTargets.forEach((t) => {
      if (t.value != "") {
        total += parseInt(t.value);
      }
    });
    this.counterInputTarget.value = total;
  }
}
