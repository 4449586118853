import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["form", "input", "submit"];
  static values = { skip: { type: Boolean, default: false } };

  initialize() {
    this.code = [];
    this.barscannerHandler = (e) => this.barscanner(e);
    window.addEventListener("keydown", this.barscannerHandler);
  }

  disconnect() {
    this.code = [];
    window.removeEventListener("keydown", this.barscannerHandler);
    clearTimeout(this.timer);
  } 

  barscanner(e) {
    if (this.skipValue) {
      // In some cases we want to temp skip this function. I.e. when POS confirmation modal is open
      return;
    }
    // keyCode 13 = Enter
    if (e.key.length > 1 && e.keyCode != 13) {
      // All keys are triggered by this event, also the keyboard keypress
      // Skip all the "meta" keypress (ctrl, cmd, alt...)
      // The spacebar key iis " " and will be skipped with a regex
      // The "Enter" key is not skipped because is the final key passed by the barcode scanner
      return;
    }

    const pattern = new RegExp(/^[a-zA-Z0-9]+$/);
    if (e.keyCode == 13) {
      const code = this.code.join("");
      if (pattern.test(code)) {
        e.preventDefault();
        this.inputTarget.value = code;
        if (this.hasSubmitTarget) {
          // on POS page we need to simulate a click to call `pos--search#checkIfProductExistBySku` action
          this.submitTarget.click();
        } else {
          Turbo.navigator.submitForm(this.formTarget);
        }
      }
      this.code = [];
    } else {
      this.code.push(e.key);
    }

    // Every digit is a keydown and on barcodes are sequentials.
    // On this function also keyboard keydown are triggered, if the sequence is too slow cleanup the code array
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.code = [];
    }, 1000);
  }
}
