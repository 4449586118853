import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="products--title-validation"
export default class extends Controller {
  static targets = ["badge"];

  validate(e) {
    if (e.target.value.length > 99) {
      this.badgeTarget.classList.remove("hide");
    } else {
      this.badgeTarget.classList.add("hide");
    }
  }
}
