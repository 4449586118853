import { Turbo } from "@hotwired/turbo-rails";

const postToUrl = (path, params = {}, method = "post") => {
  let form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);

  Object.entries(params).forEach(([name, value]) => {
    let hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", name);
    hiddenField.setAttribute("value", value);
    form.appendChild(hiddenField);
  });

  Turbo.navigator.submitForm(form);
};

export { postToUrl };
