import { Controller } from "@hotwired/stimulus";
import { postToUrl } from "../../lib/helpers";

// Connects to data-controller="products--images-reload"
export default class extends Controller {
  static values = { url: String };

  connect() {
    this.interval = setInterval(() => {
      postToUrl(this.urlValue);
    }, 5000);
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
