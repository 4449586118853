import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import AWS from "aws-sdk";
import Sortable from "sortablejs";

// https://stackoverflow.com/questions/34526851/upload-files-to-amazon-s3-with-dropzone-js-issue
// https://github.com/kfei/vue-s3-dropzone/blob/master/frontend/src/components/Dropzone.vue

export default class extends Controller {
  static values = { key: String, bucket: String, region: String, name: String };

  initialize() {
    const element = this.element;
    const poolID = this.keyValue;
    const bucket = this.bucketValue;
    const region = this.regionValue;
    // Make sure Dropzone doesn't try to attach itself to the
    // element automatically.
    // This behaviour will change in future versions.
    Dropzone.autoDiscover = false;
    const vm = this;

    let options = {
      thumbnailMethod: "contain",
      // The URL will be changed for each new file being processing
      url: "/",

      // Since we're going to do a `PUT` upload to S3 directly
      method: "put",

      previewTemplate: document.querySelector("#dropzone-template-container").innerHTML,
      previewsContainer: `#${element.id} .drop-images-wrapper`,
      clickable: `#${element.id} .open-dropzone`,

      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        let _send = xhr.send;
        xhr.send = () => {
          _send.call(xhr, file);
        };
      },

      // Upload one file at a time since we're using the S3 pre-signed URL scenario
      parallelUploads: 1,
      uploadMultiple: false,

      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      header: "",

      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,

      // Here we request a signed upload URL when a file being accepted
      accept(file, done) {
        AWS.config.region = region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: poolID,
        });

        const s3 = new AWS.S3();
        const randomFolder = Math.floor(Math.random() * 100000000);
        // Remove special chars and white spaces
        const fileName = file.name.replace(/[^A-Za-z0-9\-\_\.]/g, "-");
        var params = {
          Bucket: bucket,
          Key: `tmp/${randomFolder}/${fileName}`,
          ACL: "public-read",
          ContentType: file.type,
          Expires: 1800, // 30m
        };

        s3.getSignedUrl("putObject", params, function (err, url) {
          if (err) {
            done("Failed to get an S3 signed upload URL", err);
          } else {
            file.uploadURL = url;
            done();
            // Manually process each file
            setTimeout(() => vm.dropzone.processFile(file));
          }
        });
      },
    };

    // Instantiate Dropzone
    this.dropzone = new Dropzone(element, options);

    // Set signed upload URL for each file
    vm.dropzone.on("processing", (file) => {
      vm.dropzone.options.url = file.uploadURL;
    });
    vm.dropzone.on("addedfile", (file) => {
      // hide upload button
      element.classList.add("dz-started");
    });
    vm.dropzone.on("success", (file) => {
      // Remove query string from url
      const fileUrl = file.uploadURL.split("?")[0];
      let namePrefix = vm.nameValue;
      const randomNumber = Math.floor(Math.random() * 100000);
      namePrefix = `${namePrefix}[${randomNumber}]`;
      // Set temp url on hiddden input
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", `${namePrefix}[s3_url]`);
      input.setAttribute("value", fileUrl);
      file.previewElement.getElementsByClassName("inputs")[0].appendChild(input);
      // Set destroy input
      let destroyInput = document.createElement("input");
      destroyInput.setAttribute("type", "hidden");
      destroyInput.setAttribute("name", `${namePrefix}[_destroy]`);
      destroyInput.setAttribute("value", 0);
      destroyInput.setAttribute("data-image-target", "destroyInput");
      file.previewElement.getElementsByClassName("inputs")[0].appendChild(destroyInput);
      let positionInput = document.createElement("input");
      positionInput.setAttribute("type", "hidden");
      positionInput.setAttribute("name", `${namePrefix}[position]`);
      positionInput.setAttribute("value", "");
      positionInput.setAttribute("data-sortable-target", "positionInput");
      file.previewElement.getElementsByClassName("inputs")[0].appendChild(positionInput);

      // Send a Stimulus event to force a refresh of the sortable positions
      this.dispatch("refresh")
    });
  }
}
