import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "tabPanel"];
  static values = { index: Number };

  change(e) {
    this.indexValue = this.tabTargets.indexOf(e.currentTarget);
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.tabPanelTargets[index];
      tab.classList.toggle("active", index == this.indexValue);
      panel.classList.toggle("hide", index != this.indexValue);
    });
  }

  indexValueChanged() {
    this.showTab();
  }
}
