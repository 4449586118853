import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="pos--open-confirmation"
export default class extends Controller {
  static outlets = ["barscanner"];
  static targets = ["confirmBtn"];

  connect() {
    this.keyDownHandler = (e) => this.onKeyDown(e);
    window.addEventListener("keydown", this.keyDownHandler);
  }

  disconnect() {
    window.removeEventListener("keydown", this.keyDownHandler);
  }

  onKeyDown(e) {
    if (e.keyCode == 13 && e.ctrlKey) {
      // When press "enter" and "ctrl" show the confirmation modal
      e.preventDefault();
      this.confirmBtnTarget.click();
    }
  }

  disableBarscanner() {
    this.barscannerOutlet.skipValue = true;
  }
}
