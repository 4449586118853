import { Controller } from "@hotwired/stimulus";
import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Legend,
  Filler,
  Tooltip,
} from "chart.js";

export default class extends Controller {
  static values = { dataset: Object };

  connect() {
    Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Legend, Filler, Tooltip);

    this.chart = new Chart(this.element, {
      type: "line",
      data: this.datasetValue,
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
          tooltip: {
            callbacks: {
              footer: this.dataSum,
              label: this.labelFormat,
            },
          },
        },
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        scales: {
          y: {
            stacked: true,
          },
        },
      },
    });
  }

  disconnect() {
    this.chart.destroy();
    this.chart = undefined;
  }

  dataSum(tooltipItems) {
    // Add a sum at the tooltip bottom
    let sum = 0;
    tooltipItems.forEach((tooltipItem) => (sum += tooltipItem.parsed.y));
    return `Somma: € ${new Intl.NumberFormat("it-IT").format(sum.toFixed(2))}`;
  }

  labelFormat(context) {
    // Format numbers for the label of each dataset
    let label = context.dataset.label || "";

    if (label) {
      label += ": € ";
    }
    if (context.parsed.y !== null) {
      return `${label} ${new Intl.NumberFormat("it-IT").format(context.parsed.y.toFixed(2))}`;
    }
  }
}
