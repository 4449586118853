// https://github.com/mebjas/html5-qrcode
import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";

// Connects to data-controller="qrcode-reader"
export default class extends Controller {
  // isUrl: the readed code has an URL so we can simply redirect the page
  // url: is the URL to post and make a search by code
  static values = {
    isUrl: { type: Boolean, default: true },
    url: { type: String, default: "" },
  };

  connect() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // Mobile device
      this.qrcode = new qrCodeReader(this.element.id, this.isUrlValue, this.urlValue);
      this.qrcode.start();
    } else {
      // Desktop
      window.location.href = "/";
    }
  }

  disconnect() {
    this.qrcode.stop();
  }
}

class qrCodeReader {
  constructor(elementId, isRedirect, url) {
    this.qrcode = new Html5Qrcode(elementId);
    this.isRedirect = isRedirect;
    this.url = url;
  }

  start() {
    const config = {
      fps: 10,
      qrbox: { width: 200, height: 200 },
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      aspectRatio: 1.0,
    };

    this.qrcode
      .start({ facingMode: "environment" }, config, (decodedText, decodedResult) => {
        this.qrCodeSuccessCallback(decodedText);
      })
      .catch((err) => {
        // Start failed, handle it.
        console.log(err);
      });
  }

  qrCodeSuccessCallback(decodedText) {
    this.stop();
    if (this.isRedirect) {
      Turbo.visit(decodedText);
    } else {
      Turbo.visit(`${this.url}/${decodedText}`);
    }
  }

  stop() {
    this.qrcode.stop();
  }
}
