import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["brandManufacturer", "brand", "manufacturer", "skuInput"];

  unlink(e) {
    e.preventDefault();
    this.brandManufacturerTarget.remove();
    this.brandTarget.classList.remove("hide");
    this.brandTarget.getElementsByClassName("dropdown")[0].classList.remove("disabled");
    this.brandTarget.getElementsByTagName("select")[0].removeAttribute("disabled");
    this.manufacturerTarget.classList.remove("hide");
    this.manufacturerTarget.getElementsByClassName("dropdown")[0].classList.remove("disabled");
    this.manufacturerTarget.getElementsByTagName("select")[0].removeAttribute("disabled");
  }

  getSku(e) {
    e.preventDefault();
    const input = this.skuInputTarget;
    $.post("/products/generate-sku", function (data) {
      input.value = data.sku;
    });
  }
}
