import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import $ from "jquery";

export default class extends Controller {
  static targets = ["productSku"];
  static values = { product: String };

  submitForm(e) {
    // Assign product id to a hidden field and submit form to build a order line
    e.preventDefault();
    if (this.hasProductValue) {
      let updated = false;
      $("#produt-search-results").html("").removeClass("grid-container-results");
      $("#js-search-product").val("");
      // Check if the product is already present on form and update his quantity
      $(".order-detail-line-edit").each((i, obj) => {
        if ($(obj).find(".js-order-line-product-id").length == 1) {
          const product = $(obj).find(".js-order-line-product-id").val();
          // Product already exists on POS
          if (product == this.productValue) {
            updated = true;
            // Increase line quantity
            this.updateLineQuantity($(obj));
            return;
          }
        }
      });
      // No product found: submit the form to build the line
      if (!updated) {
        $("#js-add-product").val(this.productValue);
        Turbo.navigator.submitForm(document.getElementById("js-add-product-form"));
      }
    }
  }

  checkIfProductExistBySku(e) {
    // Function used by barscanner search
    // This function need to check if a product is already prensent on POS.
    // If exists increase the quantity instead of making a request to build a new line
    if (this.productSkuTarget.value) {
      $(".order-detail-line-edit").each((i, obj) => {
        if ($(obj).find(".js-order-line-product-sku").length == 1) {
          const product = $(obj).find(".js-order-line-product-sku").val();
          // Product already exists on POS
          if (product == this.productSkuTarget.value) {
            // Stop form submit
            e.preventDefault();
            e.stopImmediatePropagation();
            // Remove the produtc_sku from form bescause is not submitted
            this.productSkuTarget.value = "";
            $("#js-search-product").val("");
            // Increase line quantity
            this.updateLineQuantity($(obj));
            return;
          }
        }
      });
    }
  }

  updateLineQuantity(element) {
    // element is the HTML line passed by jQuery
    // Increase and set new quantity
    const quantity = (parseInt(element.find(".js-order-details-line-quantity").val()) || 0) + 1;
    element.find(".js-order-details-line-quantity").val(quantity);
    // Create a custom event that calls a function present on calculation controller
    // An action on the pos line will listen to this custom event and fire the function on calculation controller
    const event = new CustomEvent("forceCalculateItemsTotal", { bubbles: true, cancelable: true });
    document.dispatchEvent(event);
  }

  searchOnError(e) {
    if (!e.detail.success) {
      $("#js-search-product").val(this.productSkuTarget.value);
    }
  }
}
