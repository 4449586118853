import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import $ from "jquery";

export default class extends Controller {
  static targets = ["productSku"];
  static values = { product: String };

  submitForm(e) {
    // Assign product id to a hidden field and submit form to build a order line
    e.preventDefault();

    if (this.hasProductValue) {
      $("#js-add-product").val(this.productValue);
      Turbo.navigator.submitForm(document.getElementById("js-add-product-form"));
    }
  }

}
