import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// Connects to data-controller="scrollto"
export default class extends Controller {
  connect() {
    const el = this.element;
    $("#main-container, .modal.open").animate({ scrollTop: $(el).offset().top - 70 }, 1000);
  }
}
