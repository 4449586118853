import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["input", "selectAllCheckbox"];
  static values = {
    selectOnLoad: { type: Boolean, default: false },
  };

  connect() {
    if (this.selectOnLoadValue && this.hasInputTarget) {
      this.selectAllCheckboxTarget.click();
    }

    // Select the rows after a filter change
    const footer = document.getElementById("footer-fixed");
    let selectedValues = JSON.parse(
      footer.getAttribute("data-footer-actions-ids-value")
    );
    console.log(selectedValues)
    selectedValues.forEach((value) => {
      const elementId = "filled-in-box-" + value;
      const element = document.getElementById(elementId);
      
      if (element) {
        element.click();
      }
    });

  }

  toggleId(e) {
    const footer = document.getElementById("footer-fixed");
    let selectedValues = JSON.parse(
      footer.getAttribute("data-footer-actions-ids-value")
    );

    if (e.target.checked) {
      selectedValues.push(e.target.value);
    } else {
      _.pull(selectedValues, e.target.value);
    }
    selectedValues = [...new Set(selectedValues)]; // Remove duplicates
    footer.setAttribute(
      "data-footer-actions-ids-value",
      JSON.stringify(selectedValues)
    );
    const button_counter = footer.querySelector(".elements-counter");
    button_counter.innerText = " - " + selectedValues.length + " elementi";
  }

  toggleAll(e) {
    if (e.target.checked) {
      _.filter(this.inputTargets, (target) => !target.checked).map((target) =>
        target.click()
      );
    } else {
      _.filter(this.inputTargets, (target) => target.checked).map((target) =>
        target.click()
      );
    }
  }
}
