import { Controller } from "@hotwired/stimulus";
import { getPrinters } from "../lib/qz-helper";

export default class extends Controller {
  static targets = ["products", "shipping", "documents"];
  static values = { products: String, shipping: String, documents: String };

  connect() {
    getPrinters()
      .then((printers) => {
        setTimeout(() => {
          // Add found printers to the select
          printers.map((p) => {
            this.shippingTarget.add(new Option(p, p));
            this.documentsTarget.add(new Option(p, p));

            if (this.hasProductsTarget) {
              this.productsTarget.add(new Option(p, p));
            }
          });
          // Add current printer to the select if is not present on printers array
          if (this.shippingValue != "" && printers.indexOf(this.shippingValue) < 0) {
            this.shippingTarget.add(new Option(`${this.shippingValue} (scollegata)`, this.shippingValue));
          }
          if (this.documentsValue != "" && printers.indexOf(this.documentsValue) < 0) {
            this.documentsTarget.add(new Option(`${this.documentsValue} (scollegata)`, this.documentsValue));
          }
          // Select the option if on db there is already a printer
          this.documentsTarget.value = this.documentsValue;
          this.shippingTarget.value = this.shippingValue;
          if (this.hasProductsTarget) {
            if (this.productsValue != "" && printers.indexOf(this.productsValue) < 0) {
              this.productsTarget.add(new Option(`${this.productsValue} (scollegata)`, this.productsValue));
            }
            this.productsTarget.value = this.productsValue;
          }
          document.getElementById("printer-loading").classList.add("hide");
          document.getElementById("printers-selct-box").classList.remove("hide");
        }, 1500);
      })
      .catch(() => {
        document.getElementById("printer-loading").classList.add("hide");
        document.getElementById("printer-error").classList.remove("hide");
      });
  }
}
