import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { ids: Array };

  connect() {}

  idsValueChanged() {
    document.getElementById("process-record-ids").value = JSON.stringify(
      this.idsValue
    );

    if (this.idsValue.length > 0) {
      document
        .getElementById("footer-fixed")
        .classList.add("footer-enter-active");
    } else {
      document
        .getElementById("footer-fixed")
        .classList.remove("footer-enter-active");
    }
  }
}
