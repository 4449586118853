import { Controller } from "@hotwired/stimulus";
import { postToUrl } from "../../lib/helpers";

// Connects to data-controller="suppliers-orders--line"
export default class extends Controller {
  static targets = [
    "purchaseInput",
    "purchaseVariant",
    "purchaseDiscountInput",
    "purchaseDiscountVariant",
    "purchaseVatRateInput",
    "purchaseVatRateVariant",
    "priceInput",
    "priceVariant",
    "salePriceInput",
    "salePriceVariant",
    "weightInput",
    "weightVariant",
    "vatRateInput",
    "vatRateVariant",
    "destroy",
    "cachedInput",
  ];

  connect() {
    this.syncValues("purchase");
    this.syncValues("purchaseDiscount");
    this.syncValues("price");
    this.syncValues("salePrice");
    this.syncValues("weight");
    this.syncValues("vatRate");
  }

  syncPurchasePrice() {
    this.syncValues("purchase");
  }

  syncPurchaseDiscount() {
    this.syncValues("purchaseDiscount");
  }

  syncPrice() {
    this.syncValues("price");
  }

  syncSalePrice() {
    this.syncValues("salePrice");
  }

  syncWeight() {
    this.syncValues("weight");
  }

  syncVatRate() {
    this.syncValues("vatRate");
  }

  syncPurchaseVatRate() {
    this.syncValues("purchaseVatRate");
  }

  syncValues(targetName) {
    if (this[`${targetName}InputTarget`].disabled) {
      return;
    }
    this[`${targetName}VariantTargets`].forEach((element) => {
      element.value = this[`${targetName}InputTarget`].value;
    });
  }

  destroyLine(e) {
    e.preventDefault();
    this.element.classList.add("hide");
    this.destroyTarget.value = 1;
  }

  postToVariants(e) {
    // We need to post to the server all the line values otherwise the new modal with variants will be incongruent
    // We can't use a form in this case because we already have a parent form for the supplier order
    e.preventDefault();
    let values = {};
    this.cachedInputTargets.forEach((el) => (values[el.name] = el.value));
    postToUrl(e.target.closest("a").getAttribute("href"), values);
  }
}
