import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["form", "input"];

  submitForm() {
    // submit product search form after 800ms and when value have at least 4 chars
    clearTimeout(this.timer);
    if (this.inputTarget.value.length > 3) {
      this.timer = setTimeout(() => {
        Turbo.navigator.submitForm(this.formTarget);
      }, 800);
    }
  }
}
