import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// Connects to data-controller="sidebar-filter"
export default class extends Controller {
  static values = {
    inputs: { type: Array, default: [] },
    selects: { type: Array, default: [] },
  };

  openFilter(e) {
    e.preventDefault();
    document.getElementsByTagName("body")[0].classList.toggle("filter-open");
    this.inputsValue.forEach((value) => {
      document.getElementById(`sidebar-${value}`).value = document.getElementById(`top-${value}`).value;
    });

    this.selectsValue.forEach((value) => {
      $(`#sidebar-${value}`).val($(`#top-${value}`).val());
      $(`#sidebar-${value}`).trigger("change"); // Notify any JS components that the value changed
    });
  }

  closeFilter(e) {
    e.preventDefault();
    document.getElementsByTagName("body")[0].classList.remove("filter-open");

    this.inputsValue.forEach((value) => {
      document.getElementById(`top-${value}`).value = document.getElementById(`sidebar-${value}`).value;
    });

    this.selectsValue.forEach((value) => {
      $(`#top-${value}`).val($(`#sidebar-${value}`).val());
      $(`#top-${value}`).trigger("change");
    });
  }
}
