import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="products--footer-actions"
export default class extends Controller {
  static targets = ["suppliersOrdersSelect", "submitButton"];

  onActionChange(e) {
    if (e.target.value == "suppliers-orders") {
      this.suppliersOrdersSelectTarget.classList.remove("hide");
      this.submitButtonTarget.classList.remove("hide");
    } else {
      this.suppliersOrdersSelectTarget.classList.add("hide");
      this.submitButtonTarget.classList.add("hide");
    }
  }
}
