// https://github.com/stimulus-components/stimulus-sortable/blob/master/src/index.js
import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["positionInput"];
  static values = { trigger: String };

  initialize() {
    // Don't know exactly what is "bind" but without it we can't access to this.positionInputTargets on end function
    this.end = this.end.bind(this);
  }

  connect() {
    const trigger = this.triggerValue || null;

    this.sortable = new Sortable(this.element, {
      onEnd: this.end,
      filter: ".skip-sort",
      handle: trigger,
    });
  }
  disconnect() {
    this.sortable.destroy();
    this.sortable = undefined;
  }
  end() {
    this.positionInputTargets.map((target, i) => {
      target.value = i + 1;
    });
  }

  refresh() {
    this.end()
  }

}
