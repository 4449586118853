import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["input"];

  open(e) {
    e.preventDefault();
    this.inputTarget.click();
  }

  submit() {
    Turbo.navigator.submitForm(this.element);
  }
}
