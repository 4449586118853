import $ from "jquery";
import qz from "qz-tray";

let connected = false;

qz.api.setPromiseType(function promise(resolver) {
  return new Promise(resolver);
});

qz.security.setCertificatePromise((resolve, reject) => $.get("/public_key.pem").then(resolve, reject));

qz.security.setSignaturePromise((toSign) => {
  return (resolve, reject) => {
    $.get("/printers/certificate?m=" + toSign).then(resolve, reject);
  };
});

const qzConnect = () => {
  if (connected) return Promise.resolve();

  return new Promise((resolve, reject) =>
    qz.websocket
      .connect()
      .then(() => {
        connected = true;
        resolve();
      })
      .catch((error) => {
        connected = false;
        reject(error);
      })
  );
};

const printLabel = (label, config) => {
  const printData = label.split("\n").map((l) => l + "\n");
  qz.print(config, printData).catch((e) => {
    console.error(e);
    window.alert(
      "Non è possibile effettuare la stampa, verifica che la stampante sia attiva e configurata correttamente."
    );
  });
};

export const getPrinters = async () => {
  await qzConnect();
  return qz.printers.find();
};

export const printZPL = async (printer, data) => {
  await qzConnect();

  const config = qz.configs.create(printer);
  printLabel(data, config);
};

export const printPDF = async (printer, data) => {
  await qzConnect();

  const config = qz.configs.create(printer);
  const printData = [{ type: "pixel", format: "pdf", flavor: "base64", data: data }];

  qz.print(config, printData).catch((e) => {
    console.error(e);
    window.alert(
      "Non è possibile effettuare la stampa, verifica che la stampante sia attiva e configurata correttamente."
    );
  });
};

export const printEPL = async (printer, data) => {
  // https://trello.com/c/ssuqETzc

  await qzConnect();
  const config = qz.configs.create(printer);

  data.forEach((job, index) => {
    setTimeout(() => printLabel(job, config), index * 1000);
  });
};
