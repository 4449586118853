// https://github.com/kirillplatonov/dirty-form/blob/master/src/dirty-form.js
import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

// Connects to data-controller="dirty-form"
export default class extends Controller {
  static values = { isDirty: { Boolean: String, default: false } };

  connect() {
    this.initialValues = {};
    this.updateInitialValues();
    this.observer = new MutationObserver(this.observeFormChildUpdate.bind(this));
    this.observer.observe(this.element, { attributes: false, childList: true, subtree: true });
    this.element.addEventListener("close-modal", this.confirmCloseIfDirty.bind(this));
    window.addEventListener("beforeunload", this.preventPageReload.bind(this));
  }

  disconnect() {
    // clear initialValues otherwise "beforeunload" will always see values also if the event is removed (don't understand why)
    this.initialValues = {};
    this.observer.disconnect();
    this.element.removeEventListener("close-modal", this.confirmCloseIfDirty.bind(this));
    window.removeEventListener("beforeunload", this.preventPageReload.bind(this));
  }

  updateInitialValues() {
    this.element.elements.forEach((field) => {
      if (!field.name || field.type == "submit" || field.type == "button" || field.type == "hidden" || field.disabled) {
        return;
      }
      if (!_.has(this.initialValues, field.name)) {
        this.initialValues[field.name] = field.value;
      }
    });
  }

  observeFormChildUpdate() {
    this.updateInitialValues();
  }

  checkChanges() {
    if (_.size(this.initialValues) == 0) {
      return;
    }
    let changed = false;
    this.element.elements.forEach((field) => {
      if (
        changed ||
        !field.name ||
        field.type == "submit" ||
        field.type == "button" ||
        field.type == "hidden" ||
        field.disabled
      ) {
        return;
      }
      if (_.has(this.initialValues, field.name) && this.initialValues[field.name] != field.value) {
        changed = true;
      }
    });

    return changed;
  }

  preventPageReload(e) {
    if (this.checkChanges()) {
      e.preventDefault();
      return (e.returnValue = "");
    }
  }

  confirmCloseIfDirty(e) {
    if (this.checkChanges()) {
      const result = window.confirm("Vuoi abbandonare la pagina? Ci sono delle modifiche non salvate");
      if (!result) {
        e.preventDefault();
      }
    }
  }
}
