import { Controller } from "@hotwired/stimulus";
import "suneditor/dist/css/suneditor.min.css";
import suneditor from "suneditor";
import { fontSize, formatBlock, fontColor, hiliteColor, link, align, list } from "suneditor/src/plugins";
import it from "suneditor/src/lang/it";

export default class extends Controller {
  connect() {
    this.editor = suneditor.create(this.element, {
      lang: it,
      width: "100%",
      minHeight: 300,
      plugins: [fontSize, formatBlock, fontColor, hiliteColor, link, align, list],
      defaultStyle: "font-size: 16px;",
      buttonList: [
        [
          "bold",
          "italic",
          "strike",
          "link",
          "removeFormat",
          "align",
          "list",
          "undo",
          "redo",
          ":i-Avanzate-default.more_vertical",
          "fontSize",
          "formatBlock",
          "fontColor",
          "hiliteColor",
          "subscript",
          "superscript",
          "codeView",
        ],
      ],
    });
    this.editor.onChange = () => {
      // Update textarea value otherwise will pass to the server an unchanged value
      this.editor.save();
    };
  }

  disconnect() {
    this.editor.destroy();
    this.editor = undefined;
  }
}
