import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="inventory-switch"
export default class extends Controller {
  static targets = ["switch", "fields"];
  connect() {
    this.togglePartialFields();
  }

  togglePartialFields() {
    if (this.switchTarget.checked) {
      this.fieldsTargets.map((field) => field.classList.remove("hide"));
    } else {
      this.fieldsTargets.map((field) => field.classList.add("hide"));
    }
  }
}
