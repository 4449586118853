import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="prevent-submit-on-enter-key"
export default class extends Controller {
  initialize() {
    this.preventSubmitHandler = (e) => this.preventSubmit(e);
    window.addEventListener("keydown", this.preventSubmitHandler);
  }

  disconnect() {
    window.removeEventListener("keydown", this.preventSubmitHandler);
  }

  preventSubmit(e) {
    if (e.keyCode == 13 && !e.ctrlKey) {
      // 13 is "enter". We skip ctrl+Enter because is triggered for POS confirmation
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }
}
