// Used only on switch inside a tooltip because "label for" doesn't work
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  toggle() {
    this.inputTarget.click();
  }
}
