import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Cookies from "js-cookie";

// Connects to data-controller="products--listing"
export default class extends Controller {
  onStockChange(e) {
    if (e.target.checked) {
      Cookies.set("product_stock", "enabled", { expires: 365, sameSite: "lax" });
    } else {
      Cookies.set("product_stock", "disabled", { expires: 365, sameSite: "lax" });
    }
    Turbo.visit(window.location.href);
  }

  onStatisticsChange(e) {
    if (e.target.checked) {
      Cookies.set("product_statistics", "enabled", { expires: 365, sameSite: "lax" });
    } else {
      Cookies.set("product_statistics", "disabled", { expires: 365, sameSite: "lax" });
    }
    Turbo.visit(window.location.href);
  }
}
