import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "select",
    "pricePercent",
    "priceAbsolute",
    "salePricePercent",
    "dynamicPriceResult",
    "salePriceAbsolute",
    "dynamicSalePriceResult",
  ];
  static values = { price: Number, salePrice: Number };

  connect() {
    if (this.hasSelectTarget) {
      this.updatePices();
    }
  }

  updatePices() {
    const targetValue = this.selectTarget.value;

    switch (targetValue) {
      case "catalog":
        this.useCatalog();
        break;
      case "dynamic":
        this.useDynamic();
        break;
      case "custom":
        this.useCustom();
        break;
    }
  }

  useCatalog() {
    $(".channel-price").each((i, obj) => {
      $(obj).val($(obj).data("price"));
    });
    $(".channel-sale-price").each((i, obj) => {
      $(obj).val($(obj).data("sale-price"));
    });
    $(".channel-price, .channel-sale-price").attr("disabled", true);
    $(".static-input").removeClass("hide");
    $(".dynamic-input").addClass("hide");
  }

  useDynamic() {
    $(".static-input").addClass("hide");
    $(".dynamic-input").removeClass("hide");
    $(".channel-price, .channel-sale-price").attr("disabled", true);
  }

  useCustom() {
    $(".channel-price, .channel-sale-price").attr("disabled", false);
    $(".static-input").removeClass("hide");
    $(".dynamic-input").addClass("hide");
  }

  calculateDynamicPrice() {
    const result = this.calculate(this.pricePercentTarget.value, this.priceAbsoluteTarget.value, this.priceValue);
    this.dynamicPriceResultTarget.value = result;
  }

  calculateDynamicSalePrice() {
    // Sale price is optional
    if (isNaN(this.salePriceValue)) {
      return;
    }
    const result = this.calculate(
      this.salePricePercentTarget.value,
      this.salePriceAbsoluteTarget.value,
      this.salePriceValue
    );
    this.dynamicSalePriceResultTarget.value = result;
  }

  calculateDynamicPrices() {
    const percentage = parseFloat(this.pricePercentTarget.value) || 0;
    const absolute = parseFloat(this.priceAbsoluteTarget.value) || 0;
    $(".channel-price").each((i, obj) => {
      const price = $(obj).data("price");
      const result = this.calculate(percentage, absolute, price);
      $(obj).val(result);
    });
  }

  calculateDynamicSalePrices() {
    const percentage = parseFloat(this.salePricePercentTarget.value) || 0;
    const absolute = parseFloat(this.salePriceAbsoluteTarget.value) || 0;
    $(".channel-sale-price").each((i, obj) => {
      const price = $(obj).data("sale-price");
      if (!isNaN(price) && price != "") {
        const result = this.calculate(percentage, absolute, price);
        $(obj).val(result);
      }
    });
  }

  calculate(percentage_value, absolute_value, price) {
    const percentage = parseFloat(percentage_value) || 0;
    const absolute = parseFloat(absolute_value) || 0;
    let result = 0;
    if (percentage > 0) {
      result = price * (percentage / 100);
    }
    result = result + absolute;
    return result.toFixed(2);
  }
}
