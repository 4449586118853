import { Controller } from "@hotwired/stimulus";
import { beep, errorBeep, warningBeep } from "../lib/audio";

// Connects to data-controller="audio"
export default class extends Controller {
  static values = {
    isSuccess: { type: Boolean, default: false },
    isError: { type: Boolean, default: false },
    isWarning: { type: Boolean, default: false },
  };

  connect() {
    if (this.isSuccessValue) {
      this.successAudio();
    }
    if (this.isErrorValue) {
      this.errorAudio();
    }
    if (this.isWarningValue) {
      this.warningAudio();
    }
  }

  successAudio() {
    beep.play();
  }

  errorAudio() {
    errorBeep.play();
  }

  warningAudio() {
    warningBeep.play();
  }
}
