import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

// Connects to data-controller="variants-autocomplete"
export default class extends Controller {
  static targets = [
    "productPurchase",
    "variantPurchase",
    "productPurchaseDiscount",
    "variantPurchaseDiscount",
    "productPrice",
    "variantPrice",
    "productSalePrice",
    "variantSalePrice",
    "productQuantity",
    "variantQuantity",
    "productWeight",
    "variantWeight",
    "productVatRate",
    "variantVatRate",
    "productPurchaseVatRate",
    "variantPurchaseVatRate",
  ];

  setPurchasePrice(e) {
    e.preventDefault();
    this.variantPurchaseTargets.forEach((t) => (t.value = this.productPurchaseTarget.value));
  }

  setPurchaseDiscount(e) {
    e.preventDefault();
    this.variantPurchaseDiscountTargets.forEach((t) => (t.value = this.productPurchaseDiscountTarget.value));
  }

  setProductPrice(e) {
    e.preventDefault();
    this.variantPriceTargets.forEach((t) => (t.value = this.productPriceTarget.value));
  }

  setProductSalePrice(e) {
    e.preventDefault();
    this.variantSalePriceTargets.forEach((t) => (t.value = this.productSalePriceTarget.value));
  }

  setProductQuantity(e) {
    e.preventDefault();
    this.variantQuantityTargets.forEach((t) => (t.value = this.productQuantityTarget.value));
  }

  setProductWeight(e) {
    e.preventDefault();
    this.variantWeightTargets.forEach((t) => (t.value = this.productWeightTarget.value));
  }

  setProductVatRate(e) {
    e.preventDefault();
    this.variantVatRateTargets.forEach((t) => (t.value = this.productVatRateTarget.value));
  }

  setProductPurchaseVatRate(e) {
    e.preventDefault();
    this.variantPurchaseVatRateTargets.forEach((t) => (t.value = this.productPurchaseVatRateTarget.value));
  }
}
