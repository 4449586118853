import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["positionInput"];

  initialize() {
    // Don't know exactly what is "bind" but without it we can't access to this.positionInputTargets on end function
    this.end = this.end.bind(this);
  }
  connect() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      onEnd: this.end,
    });
  }
  disconnect() {
    this.sortable.destroy();
    this.sortable = undefined;
  }
  end() {
    this.positionInputTargets.map((target, i) => {
      target.value = i + 1;
    });
  }
}
