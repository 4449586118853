import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "element"];

  connect() {
    this.toggle();
  }

  toggle() {
    const selectValue = this.selectTarget.value;

    if (selectValue == "NO_SHIPPING") {
      this.elementTargets.map((target) => target.classList.add("hide"));
    } else {
      this.elementTargets.map((target) => target.classList.remove("hide"));
    }
  }
}
