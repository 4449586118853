// https://css-tricks.com/snippets/jquery/move-cursor-to-end-of-textarea-or-input/
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="put-cursor-at-end"
export default class extends Controller {
  connect() {
    const element = this.element;

    // If this function exists... (IE 9+)
    if (element.setSelectionRange) {
      // Double the length because Opera is inconsistent about whether a carriage return is one character or two.
      const len = element.value.length * 2;

      // Timeout seems to be required for Blink
      setTimeout(() => {
        element.setSelectionRange(len, len);
      }, 1);
    } else {
      // As a fallback, replace the contents with itself
      // Doesn't work in Chrome, but Chrome supports setSelectionRange
      const val = element.value;
      element.value = val;
    }
  }
}
