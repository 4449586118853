import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

// Connects to data-controller="popover"
export default class extends Controller {
  static targets = ["button", "content"];
  static values = {
    placement: { type: String, default: "right" },
  };

  initialize() {
    // Don't know exactly what is "bind" but without it we can't access to these functions
    this.hide = this.hide.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  connect() {
    const button = this.buttonTarget;
    const content = this.contentTarget;

    document.addEventListener("click", this.handleHide);

    this.isVisible = false;
    this.popperInstance = createPopper(button, content, {
      placement: this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
  }

  toggleVisibility() {
    if (this.isVisible) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    // Make the tooltip visible
    this.contentTarget.style.display = "block";
    this.isVisible = true;
    this.buttonTarget.classList.add("open");

    // Enable the event listeners that recalculate element position
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: "eventListeners", enabled: true }],
    }));

    // Update its position
    this.popperInstance.update();
  }

  hide() {
    // Hide the tooltip
    this.contentTarget.style.display = "none";
    this.isVisible = false;
    this.buttonTarget.classList.remove("open");

    // Disable the event listeners
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: "eventListeners", enabled: false }],
    }));
  }

  handleHide(event) {
    // event.stopPropagation();
    // event.stopImmediatePropagation();
    const button = this.buttonTarget;
    const content = this.contentTarget;

    // If the current button is clicked skip this action because is handled by toggleVisibility() function
    if (!button.contains(event.target)) {
      if (this.isVisible) {
        if (!content.contains(event.target)) {
          // If the current popover is visible and the click is outside content, close the popover
          this.hide();
        }
      }
    }
  }

  disconnect() {
    document.removeEventListener("click", this.handleHide);
  }
}
