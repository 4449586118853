import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "productPrice",
    "lineDiscount",
    "grandPrice",
    "itemsTotal",
    "itemsFixedDiscount",
    "itemsDiscountPercent",
    "orderTotal",
    "destroyInput",
    "line",
    "shippingCost",
  ];
  static values = { itemsTotal: String, recalc: Boolean };

  connect() {
    // This value is a boolean. Recalculate items total when a row is build server side
    if (this.hasRecalcValue && this.recalcValue) {
      this.calculateItemsTotal();
    }
  }

  calculateGrandPrice() {
    let discount = parseFloat(this.lineDiscountTarget.value) || 0;
    if (discount > 100) {
      // don't permit negative grand price
      discount = 0;
      this.lineDiscountTarget.value = discount;
    }
    const price = parseFloat(this.productPriceTarget.value) || 0;
    const result = (price * ((100 - discount) / 100)).toFixed(2);
    this.grandPriceTarget.value = result;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.calculateItemsTotal();
    }, 800);
  }

  calculateDiscount() {
    if (this.hasLineDiscountTarget) {
      // manual product don't have discount
      const grandPrice = parseFloat(this.grandPriceTarget.value) || 0;
      const price = parseFloat(this.productPriceTarget.value) || 0;
      let result = 0;
      if (grandPrice <= price) {
        result = 100 - (grandPrice * 100) / price;
      }
      this.lineDiscountTarget.value = result.toFixed(2);
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.calculateItemsTotal();
    }, 800);
  }

  calculateItemsTotal() {
    let total = 0;
    $(".order-detail-line-edit").each((i, obj) => {
      if ($(obj).hasClass("hide")) {
        // skip from calc deleted lines
        return true;
      }
      const quantity = parseInt($(obj).find(".js-order-details-line-quantity").val()) || 0;
      const price = parseFloat($(obj).find(".js-order-details-line-grand-price").val()) || 0;
      total += quantity * price;
    });
    // don't use $(element).data(attr, val) otherwise stimulus won't trigger the change
    $("#js-order-details-items-total").attr("data-orders--form-details-items-total-value", total.toFixed(2));
  }

  calculateTotal() {
    // Refund don't have totals because view is simplified
    if (!this.hasOrderTotalTarget) {
      return;
    }
    const itemsTotal = parseFloat(this.itemsTotalTarget.value) || 0;
    let fixedDiscount = 0;
    let discountPercent = 0;
    let shippingCost = 0;
    let discountPercentAmount = 0;
    if (this.hasItemsFixedDiscountTarget) {
      fixedDiscount = parseFloat(this.itemsFixedDiscountTarget.value) || 0;
    }
    if (this.hasItemsDiscountPercentTarget) {
      discountPercent = parseFloat(this.itemsDiscountPercentTarget.value) || 0;
    }
    if (this.hasShippingCostTarget) {
      shippingCost = parseFloat(this.shippingCostTarget.value) || 0;
    }
    if (discountPercent > 0 && discountPercent <= 100) {
      discountPercentAmount = itemsTotal * (discountPercent / 100);
    }
    this.orderTotalTarget.value = (itemsTotal + shippingCost - fixedDiscount - discountPercentAmount).toFixed(2);
  }

  itemsTotalValueChanged() {
    if (this.hasItemsTotalTarget) {
      this.itemsTotalTarget.value = this.itemsTotalValue;
      this.calculateTotal();
    }
  }

  delete(e) {
    e.preventDefault();
    this.destroyInputTarget.value = "1";
    this.lineTarget.classList.add("hide");
    this.calculateItemsTotal();
  }
}
